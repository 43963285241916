import React from "react";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { ListItemIcon } from "@material-ui/core";
import DashboardIcon from "@material-ui/icons/Dashboard";
import { Link } from "react-router-dom";

export const mainListItems = (match) => (
	<div>
		{/* <ListItem button component={Link} to={`${match.url}/set-banner`}>
      <ListItemIcon>
        <DashboardIcon />
      </ListItemIcon>
      <ListItemText primary="set banner" />
    </ListItem> */}
		{/* <ListItem button component={Link} to={`${match.url}/schedule`}>
      <ListItemIcon>
        <DashboardIcon />
      </ListItemIcon>
      <ListItemText primary="schedule" />
    </ListItem> */}
		<ListItem button component={Link} to={`${match.url}/CampaignSchedule`}>
			<ListItemIcon>
				<DashboardIcon />
			</ListItemIcon>
			<ListItemText primary="Campaign Schedule" />
		</ListItem>
		<ListItem button component={Link} to={`${match.url}/EditCampaign`}>
			<ListItemIcon>
				<DashboardIcon />
			</ListItemIcon>
			<ListItemText primary="Edit Campaign" />
		</ListItem>
		<ListItem button component={Link} to={`${match.url}/Predictions`}>
			<ListItemIcon>
				<DashboardIcon />
			</ListItemIcon>
			<ListItemText primary="Pick-4 Predictions" />
		</ListItem>
		<ListItem button component={Link} to={`${match.url}/Leaderboard`}>
			<ListItemIcon>
				<DashboardIcon />
			</ListItemIcon>
			<ListItemText primary="Pick-4 Leaderboard" />
		</ListItem>
		<ListItem button component={Link} to={`${match.url}/Submissions`}>
			<ListItemIcon>
				<DashboardIcon />
			</ListItemIcon>
			<ListItemText>
				Tournament <br /> Submissions
			</ListItemText>
		</ListItem>
		<ListItem button component={Link} to={`${match.url}/consecutive`}>
			<ListItemIcon>
				<DashboardIcon />
			</ListItemIcon>
			<ListItemText primary="consecutive" />
		</ListItem>
		<ListItem button component={Link} to={`${match.url}/users-predicions-data`}>
			<ListItemIcon>
				<DashboardIcon />
			</ListItemIcon>
			<ListItemText primary="Download csv" />
		</ListItem>
	</div>
);
