import React, { useState } from 'react';
import Title from './Title';
import axiosInstance from '../config/axios';
import { Button, LinearProgress, makeStyles, Box, Table, FormControl } from '@material-ui/core';
import { useEffect } from 'react';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import Collapse from '@material-ui/core/Collapse';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import DeleteIcon from '@material-ui/icons/Delete';
import Typography from '@material-ui/core/Typography';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import { IconButton, List, ListItem, ListItemText, ListItemSecondaryAction } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            borderBottom: 'unset',
        },
    },
    error: {
        color: 'red'
    },
    message: {
        color: 'green'
    },
    title: {
        margin: theme.spacing(4, 0, 2),
    },
    formControl: {
        margin: theme.spacing(0),
        maxWidth: 160,
        width: '100%'
    },
    pickedMatch: {
        width: '100%',
        maxWidth: 360,
        margin: theme.spacing(1),
        backgroundColor: '#f7f7f7',
    }
}));

const RoundRow = ({ round, setSelectedMatches, selectedMatches }) => {
    const [open, setOpen] = useState(false)
    const classes = useStyles();

    return <React.Fragment key={round.id}>
        <TableRow className={classes.root}>
            <TableCell>
                <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                    {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                </IconButton>
            </TableCell>
            <TableCell component="th" scope="row">
                Round - {round.name}
            </TableCell>

        </TableRow>
        <TableRow>
            <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                <Collapse in={open} timeout="auto" unmountOnExit>
                    <Box margin={1}>
                        <Table size="small" aria-label="purchases">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Match start</TableCell>
                                    <TableCell>Home Team</TableCell>
                                    <TableCell>Away Team</TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {round?.matches?.filter(m => !selectedMatches.some(sm => sm.id === m.id)).map((match) => (
                                    <TableRow key={match.date} style={{}}>
                                        <TableCell>
                                            {(new Date(match.match_start)).toLocaleString()}
                                        </TableCell>
                                        <TableCell>
                                            {match?.homeTeam?.name}
                                        </TableCell>
                                        <TableCell>
                                            {match?.awayTeam?.name}
                                        </TableCell>
                                        <TableCell>
                                            <Button
                                                type="submit"
                                                variant="contained"
                                                color="primary"
                                                onClick={() => {
                                                    setSelectedMatches([...selectedMatches, match])
                                                }}>Add to top matches</Button>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </Box>
                </Collapse>
            </TableCell>
        </TableRow>
    </React.Fragment>

}

export default function Schedule() {
    const [loading, setLoading] = useState(true);
    const [possibleLeagues, setPossibleLeagues] = useState([]);
    const [selectedLeagueID, setSelectedLeagueID] = useState('');
    const [roundsWithMatches, setRoundsWithMatches] = useState([]);
    const [selectedMatches, setSelectedMatches] = useState([]);
    const [currentRoundID, setCurrentRoundID] = useState('');

    const classes = useStyles();

    useEffect(() => {
        (async () => {
            try {
                const { data: { leagues } } = await axiosInstance.get('/admin/get-all-leagues-with-manual-schedule');
                setPossibleLeagues(leagues);
                setLoading(false);
            } catch (error) {
                console.error(error);
                setLoading(false);
            }
        })()
    }, [setPossibleLeagues])

    useEffect(() => {
        (async () => {
            try {
                if (!selectedLeagueID) return;
                setLoading(true);
                const { data: { roundsWithMatches } } = await axiosInstance.get('/admin/get-rounds-with-mathces', {
                    params: {
                        leagueId: selectedLeagueID
                    }
                })
                setRoundsWithMatches(roundsWithMatches);
                const currentRound = roundsWithMatches.find(rwm => rwm.is_current === "current_round");
                setCurrentRoundID(currentRound?.id);
                setSelectedMatches(currentRound?.matches?.filter(m => +m.isTopMatch === 1) ?? [])
                setLoading(false);
            } catch (error) {
                console.error(error);
                setLoading(false);
            }
        })()
    }, [selectedLeagueID, setRoundsWithMatches])

    const onSubmit = async () => {
        try {
            setLoading(true);
            await axiosInstance.post('/admin/save-schedule', {
                leagueId: selectedLeagueID,
                currentRoundMatchesIDs: selectedMatches.map(m => m.id),
                currentRoundId: currentRoundID,
            })
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    }

    return (<>
        <Title>Schedule</Title>
        <Box component="div" m={3}>
            <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={loading || !selectedMatches?.length}
                onClick={() => { onSubmit() }}
            >
                Save changes
            </Button>
        </Box>
        <Box component="div" m={3}>
            {possibleLeagues?.length ?
                <FormControl className={classes.formControl}>
                    <InputLabel id="select-league-label">Select League</InputLabel>
                    <Select
                        labelId="select-league-label"
                        value={selectedLeagueID}
                        onChange={e => setSelectedLeagueID(e.target.value)}>
                        {possibleLeagues.map(l => (
                            <MenuItem key={l.id} value={l.id}>{l.name}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
                : null
            }
        </Box>
        {
            roundsWithMatches?.length ?
                <>
                    <Box component="div" m={3}>
                        <FormControl className={classes.formControl}>
                            <InputLabel id="select-curr-round-label">Select current round</InputLabel>
                            <Select
                                labelId="select-curr-round-label"
                                value={currentRoundID}
                                onChange={e => setCurrentRoundID(e.target.value)}>
                                {roundsWithMatches.map(l => (
                                    <MenuItem key={l.id} value={l.id}>round - {l.name}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>
                    <>
                        <Box component="div" m={3}>
                            <Typography variant="h6" className={classes.title}>
                                Select matches for current round
                            </Typography>
                            <List>
                                {selectedMatches.map(
                                    m =>
                                        <div className={classes.pickedMatch}>
                                            <ListItem>
                                                <ListItemText
                                                    primary={`${m.homeTeam.name} - ${m.awayTeam.name}`}
                                                    secondary={(new Date(m.match_start)).toLocaleString()}
                                                />
                                                <ListItemSecondaryAction>
                                                    <IconButton onClick={() => { setSelectedMatches(selectedMatches.filter(sm => sm.id !== m.id)) }} edge="end" aria-label="delete">
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </ListItemSecondaryAction>
                                            </ListItem>
                                        </div>
                                )}
                            </List>
                        </Box>
                        <Box component="div" m={3}>
                            {
                                roundsWithMatches?.length ?
                                    roundsWithMatches?.map(rm => (
                                        <RoundRow setSelectedMatches={setSelectedMatches} selectedMatches={selectedMatches} round={rm} />
                                    ))
                                    : null
                            }
                        </Box>
                    </>
                </>
                : null
        }
        {loading && <LinearProgress color="secondary" />}
    </>
    )
}