import jwtDecode from 'jwt-decode';
import { Route, Redirect } from 'react-router-dom';

const PrivateRoute = ({ component: Component, ...rest }) => {


    const checkLogin = () => {
        try {
            const jwt = localStorage.getItem('jwt-token');
            if (!jwt) {
                return false;
            }
            const decodedJwt = jwtDecode(jwt);
            if (decodedJwt.expires < Date.now()) {
                return false;
            } else {
                return true;
            }

        } catch (error) {
            console.error("private route useEffect error", error)
        }
    }

    return (

        // Show the component only when the user is logged in
        // Otherwise, redirect the user to /signin page
        <Route {...rest} render={props => (
            checkLogin() === true ?
                <Component {...props} />
                : <Redirect to="/login" />
        )} />
    );
};

export default PrivateRoute;