import React, { useContext } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { mainListItems } from './listItems';
import Predictions from './Predictions';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  useRouteMatch,
  Redirect,
} from "react-router-dom";
import SetBanner from './set-banner';
import { Button } from '@material-ui/core';
import axiosInstance from '../config/axios';
import { StateContext } from '../state';
import Schedule from './schedule';
import Consecutive from './consecutive';
import UsersPredicionsData from './users-predictions-data';
import CampaignSchedule from './CampaignSchedule';
import EditCampaign from './EditCampaign';
import Submissions from "../components/Tournament/Submissions";
import Leaderboard from './Leaderboard';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    maxWidth: "unset"
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflowX: 'auto',
    overflowY: 'hidden',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
}));

const Dashboard = () => {
	const classes = useStyles();
	const [open, setOpen] = React.useState(true);
	const match = useRouteMatch();

	const { dispatch } = useContext(StateContext);

	const handleDrawerOpen = () => {
		setOpen(true);
	};
	const handleDrawerClose = () => {
		setOpen(false);
	};

	const logOut = () => {
		localStorage.removeItem("jwt-token");
		axiosInstance.defaults.headers.common["jwt-token"] = "";
		dispatch({ type: "CLEAR_USER" });
	};

	return (
		<Router>
			<div className={classes.root}>
				<CssBaseline />
				<AppBar
					position="absolute"
					className={clsx(classes.appBar, open && classes.appBarShift)}
				>
					<Toolbar className={classes.toolbar}>
						<IconButton
							edge="start"
							color="inherit"
							aria-label="open drawer"
							onClick={handleDrawerOpen}
							className={clsx(
								classes.menuButton,
								open && classes.menuButtonHidden
							)}
						>
							<MenuIcon />
						</IconButton>
						<Typography
							component="h1"
							variant="h6"
							color="inherit"
							noWrap
							className={classes.title}
						>
							Admin Panel
						</Typography>
						<Button color="inherit" onClick={logOut}>
							LogOut
						</Button>
					</Toolbar>
				</AppBar>
				<Drawer
					variant="permanent"
					classes={{
						paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
					}}
					open={open}
				>
					<div className={classes.toolbarIcon}>
						<IconButton onClick={handleDrawerClose}>
							<ChevronLeftIcon />
						</IconButton>
					</div>
					<Divider />
					<List>{mainListItems(match)}</List>
				</Drawer>
				<main className={classes.content}>
					<div className={classes.appBarSpacer} />
					<Container maxWidth="lg" className={classes.container}>
						<Grid container spacing={3}>
							{/* Set banner */}
							<Switch>
								<Route exact path={`${match.url}`}>
									<Redirect to={`${match.url}/Predictions`} />
								</Route>
								{/* <Route path={`${match.url}/set-banner`}>
                  <Grid item xs={12}>
                    <Paper className={classes.paper}>
                      <SetBanner />
                    </Paper>
                  </Grid>
                </Route> */}
								{/* Recent Orders */}
								<Route path={`${match.url}/Predictions`}>
									<Grid item xs={12}>
										<Paper className={classes.paper}>
											<Predictions />
										</Paper>
									</Grid>
								</Route>
								{/* <Route path={`${match.url}/schedule`}>
                  <Grid item xs={12}>
                    <Paper className={classes.paper}>
                      <Schedule />
                    </Paper>
                  </Grid>
                </Route> */}
								<Route path={`${match.url}/CampaignSchedule`}>
									<Grid item xs={12}>
										<Paper className={classes.paper}>
											<CampaignSchedule />
										</Paper>
									</Grid>
								</Route>
								<Route path={`${match.url}/Leaderboard`}>
									<Grid item xs={12}>
										<Paper className={classes.paper}>
											<Leaderboard />
										</Paper>
									</Grid>
								</Route>
								<Route path={`${match.url}/EditCampaign`}>
									<Grid item xs={12}>
										<Paper className={classes.paper}>
											<EditCampaign />
										</Paper>
									</Grid>
								</Route>
								<Route path={`${match.url}/consecutive`}>
									<Grid item xs={12}>
										<Paper className={classes.paper}>
											<Consecutive />
										</Paper>
									</Grid>
								</Route>
								<Route path={`${match.url}/users-predicions-data`}>
									<Grid item xs={12}>
										<Paper className={classes.paper}>
											<UsersPredicionsData />
										</Paper>
									</Grid>
								</Route>
								<Route path={`${match.url}/Submissions`}>
									<Grid item xs={12}>
										<Paper className={classes.paper}>
											<Submissions />
										</Paper>
									</Grid>
								</Route>
							</Switch>
						</Grid>
					</Container>
				</main>
			</div>
		</Router>
	);
};

export default Dashboard;