import React, { useState } from "react";
import Title from "./Title";
import axiosInstance from "../config/axios";
import {
	Button,
	LinearProgress,
	makeStyles,
	Box,
	FormControl,
} from "@material-ui/core";
import { useEffect } from "react";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";

const useStyles = makeStyles((theme) => ({
	root: {
		"& > *": {
			borderBottom: "unset",
		},
	},
	error: {
		color: "red",
	},
	message: {
		color: "green",
	},
	title: {
		margin: theme.spacing(4, 0, 2),
	},
	formControl: {
		margin: theme.spacing(0),
		maxWidth: 160,
		width: "100%",
	},
}));

export default function UsersPredicionsData() {
	const [loading, setLoading] = useState(true);
	const [possibleClients, setPossibleClients] = useState([]);
	const [selectedClientID, setSelectedClientID] = useState("");
	const [possibleCampaigns, setPossibleCampaigns] = useState([]);
	const [selectedCampaignID, setSelectedCampaignID] = useState("");

	const classes = useStyles();

	useEffect(() => {
		(async () => {
			try {
				const {
					data: { clients },
				} = await axiosInstance.get("/admin/get-all-clients");
				const currentClient = process.env.REACT_APP_ClientId
					? clients.filter((c) => +c.id === +process.env.REACT_APP_ClientId)
					: clients;
				setPossibleClients([...currentClient]);
				setLoading(false);
			} catch (error) {
				console.error(error);
				setLoading(false);
			}
		})();
	}, [setPossibleClients]);

	useEffect(() => {
		(async () => {
			try {
                if (!selectedClientID) return;
				const {
					data: { campaigns },
				} = await axiosInstance.get("/admin/get-all-client-campaigns", {
					params: {
						clientId: selectedClientID,
					},
				});
				setPossibleCampaigns(campaigns);
				setLoading(false);
			} catch (error) {
				console.error(error);
				setLoading(false);
				alert(error);
			}
		})();
	}, [selectedClientID, setPossibleCampaigns]);

	const handleDownloadFile = async () => {
		try {
			setLoading(true);
			const res = await axiosInstance({
				method: "GET",
				url: "/admin/get-admin-data-csv?campaignId=" + selectedCampaignID,
				responseType: "blob",
			});
			const url = window.URL.createObjectURL(new Blob([res.data]));
			const link = document.createElement("a");
			link.href = url;
			link.setAttribute("download", "user_predictions_data.csv"); //or any other extension
			document.body.appendChild(link);
			link.click();
			link.remove();
			setLoading(false);
		} catch (error) {
			setLoading(false);
		}
	};

	return (
		<>
			<Title>Download predictions data</Title>
			<Box component="div" m={3} mb={0}>
				<FormControl className={classes.formControl}>
					<InputLabel id="select-client-label">Client App</InputLabel>
					<Select
						labelId="select-campagin-label"
						value={selectedClientID}
						onChange={(e) => setSelectedClientID(e.target.value)}
					>
						{possibleClients.map((l) => (
							<MenuItem key={l.id} value={l.id}>
								{l.name}
							</MenuItem>
						))}
					</Select>
				</FormControl>
			</Box>
			<Box component="div" m={3}>
				{selectedClientID && possibleCampaigns?.length ? (
					<FormControl className={classes.formControl}>
						<InputLabel id="select-league-label">Select Campaign</InputLabel>
						<Select
							labelId="select-league-label"
							value={selectedCampaignID}
							onChange={(e) => setSelectedCampaignID(e.target.value)}
						>
							{possibleCampaigns.map((l) => (
								<MenuItem key={l.id} value={l.id}>
									{l.name}
								</MenuItem>
							))}
						</Select>
					</FormControl>
				) : null}
			</Box>
			<Box component="div" m={3}>
				<Button
					type="submit"
					variant="contained"
					color="primary"
					disabled={loading || !selectedCampaignID}
					onClick={() => {
						handleDownloadFile();
					}}
				>
					Download Campaign predictions .csv file
				</Button>
			</Box>
			{loading && <LinearProgress color="secondary" />}
		</>
	);
}
