import './App.css';
import Dashboard from './components/Dashboard';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";
import Login from './components/Login';
import { useEffect, useState, useReducer } from 'react';
import { StateContext, stateReducer } from './state';
import axiosInstance from './config/axios';
import PrivateRoute from './components/private-route';


function App() {
  const [loading, setLoading] = useState(true)
  const [state, dispatch] = useReducer(stateReducer)

  useEffect(() => {
    const jwtToken = localStorage.getItem('jwt-token');
    if (!jwtToken) {
      setLoading(false);
    } else {
      axiosInstance.defaults.headers.common['jwt-token'] = jwtToken;
      axiosInstance.get('/auth/profile')
        .then(res => {
          if (res?.data?.user) {
            dispatch({ type: "SET_USER", payload: res.data.user });
          } else {
            dispatch({ type: "CLEAR_USER" });
            localStorage.removeItem('jwt-token');
            axiosInstance.defaults.headers.common['jwt-token'] = undefined;
          }

          setLoading(false);
        })
        .catch(err => {
          localStorage.removeItem('jwt-token');
          dispatch({ type: "CLEAR_USER" });
          axiosInstance.defaults.headers.common['jwt-token'] = undefined;
          setLoading(false);
        });
    }
  }, [])


  if (loading) {
    return <div>Loading...</div>

  }
  else {
    return (
      <div className="App">
        <StateContext.Provider value={{ state, dispatch }}>
          <Router>
            <Switch>
              <Route path="/login">
                <Login />
              </Route>
              <Route exact path="/">
                <Redirect to="/dashboard" />
              </Route>
              <PrivateRoute path="/dashboard" component={Dashboard} />
            </Switch>
          </Router>
        </StateContext.Provider>
      </div>
    );
  }
}

export default App;
