import React, { useState } from "react";
import Title from "./Title";
import axiosInstance from "../config/axios";
import {
  Button,
  LinearProgress,
  makeStyles,
  Box,
  Table,
  TableFooter,
  TablePagination,
  TableSortLabel,
  FormControl,
  Input,
} from "@material-ui/core";
import { useEffect } from "react";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import DeleteIcon from "@material-ui/icons/Delete";
import Typography from "@material-ui/core/Typography";
import {
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
  error: {
    color: "red",
  },
  message: {
    color: "green",
  },
  title: {
    margin: theme.spacing(4, 0, 2),
  },
  formControl: {
    margin: theme.spacing(0),
    maxWidth: 160,
    width: "100%",
  },
  pickedMatch: {
    width: "100%",
    maxWidth: 360,
    margin: theme.spacing(1),
    backgroundColor: "#f7f7f7",
  },
  statusBox: {
    height: 10,
    width: 10,
    border: "1px solid black",
    margin: 5,
    display: "inline-block",
  },
  successBox: {
    backgroundColor: "green",
  },
  failureBox: {
    backgroundColor: "red",
  },
  showBtn: {
    marginLeft: 20,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  mask: {
    position: "absolute",
    backgroundColor: "#FFFFFF88",
    zIndex: 9999,
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
  },
}));

const ROWS_PER_PAGE_DEFAULT = 10;

const EditCampaign = (props) => {
  const [loading, setLoading] = useState(true);
  const [disabled, setDisabled] = useState(true);
  const [possibleClients, setPossibleClients] = useState([]);
  const [selectedClientID, setSelectedClientID] = useState("");
  const [possibleCampaigns, setPossibleCampaigns] = useState([]);
  const [selectedCampaignID, setSelectedCampaignID] = useState("");
  const [matches, setMatches] = useState([]);
  const [selectedMatches, setSelectedMatches] = useState([]);
  const [roundMatches, setRoundMatches] = useState([]);
  const [orderBy, setOrderBy] = useState("match_start");
  const [order, setOrder] = useState("asc");
  const [count, setCount] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(ROWS_PER_PAGE_DEFAULT);
  const [page, setPage] = useState(0);
  const [possibleRounds, setPossibleRounds] = useState([]);
  const [selectedRoundID, setSelectedRoundID] = useState("");

  const classes = useStyles();

  const createSortHandler = (property) => (event) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangeRowsPerPage = (e) => {
		const newRowPerPage = +e?.target?.value;
		if (!newRowPerPage) return;

		const skip = page * rowsPerPage - ((page * rowsPerPage) % newRowPerPage);
		const newPage = skip / newRowPerPage;

		setRowsPerPage(newRowPerPage);
		setPage(newPage);
	};

	useEffect(() => {
		(async () => {
			try {
				const {
					data: { clients },
				} = await axiosInstance.get("/admin/get-all-clients");
				const currentClient = process.env.REACT_APP_ClientId
					? clients.filter((c) => +c.id === +process.env.REACT_APP_ClientId)
					: clients;
				setPossibleClients([...currentClient]);
				setLoading(false);
			} catch (error) {
				console.error(error);
				setLoading(false);
			}
		})();
	}, [setPossibleClients]);

  useEffect(() => {
    (async () => {
      try {
        if (!selectedClientID) return;
        const {
          data: { campaigns },
        } = await axiosInstance.get("/admin/get-all-client-campaigns", {
          params: {
            clientId: selectedClientID,
          },
        });
        setPossibleCampaigns(campaigns);
        setLoading(false);
      } catch (error) {
        console.error(error);
        setLoading(false);
      }
    })();
  }, [selectedClientID, setPossibleCampaigns]);

  useEffect(() => {
    (async () => {
      try {
        if (!selectedCampaignID) return;
        setLoading(true);
        const skip = +page * +rowsPerPage;
        const {
          data: { matches, count },
        } = await axiosInstance.get("/admin/get-all-matches", {
          params: {
            skip: skip,
            pageSize: rowsPerPage,
            order: order,
            orderBy: orderBy,
          },
        });
        setCount(count);
        setMatches(matches);
        setLoading(false);
      } catch (error) {
        console.error(error);
        setLoading(false);
      }
    })();
  }, [selectedCampaignID, rowsPerPage, page, order, orderBy]);

  useEffect(() => {
    (async () => {
      try {
        const {
          data: { rounds },
        } = await axiosInstance.get("/admin/get-all-campaign-rounds", {
          params: {
            campaignId: selectedCampaignID,
          },
        });
        setPossibleRounds(rounds.sort((a, b) => a.name - b.name));
        setLoading(false);
      } catch (error) {
        console.error(error);
        setLoading(false);
      }
    })();
  }, [selectedCampaignID, setPossibleRounds]);

  useEffect(() => {
    (async () => {
      try {
        const {
          data: { matches },
        } = await axiosInstance.get("/admin/get-campaign-round-matches", {
          params: {
            roundId: selectedRoundID,
          },
        });
        console.log({ matches });
        setSelectedMatches(matches);
        setLoading(false);
      } catch (error) {
        console.error(error);
        setLoading(false);
      }
    })();
  }, [selectedRoundID]);

  const onSubmit = async () => {
    try {
      setLoading(true);
      await axiosInstance.post("/admin/save-campaign-schedule", {
        campaignId: selectedCampaignID,
        currentRoundMatchesIDs: selectedMatches.map((m) => m.id),
        currentRoundId: selectedRoundID,
      });
      setLoading(false);
      setDisabled(true);
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <>
      <Title>Edit Campaign Round</Title>
      <Box component="div" m={3}>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          disabled={disabled}
          onClick={onSubmit}
        >
          Save Changes
        </Button>
      </Box>
      <Box component="div" m={3} mb={0}>
        <FormControl className={classes.formControl}>
          <InputLabel id="select-client-label">Client App</InputLabel>
          <Select
            labelId="select-campagin-label"
            value={selectedClientID}
            onChange={(e) => setSelectedClientID(e.target.value)}
          >
            {possibleClients.map((l) => (
              <MenuItem key={l.id} value={l.id}>
                {l.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      <Box component="div" m={3}>
        {selectedClientID && possibleCampaigns?.length ? (
          <FormControl className={classes.formControl}>
            <InputLabel id="select-campagin-label">Select Campaign</InputLabel>
            <Select
              labelId="select-campagin-label"
              value={selectedCampaignID}
              onChange={(e) => {
                setPossibleRounds([]);
                setRoundMatches([]);
                setSelectedRoundID("");
                setSelectedCampaignID(e.target.value);
              }}
            >
              {possibleCampaigns.map((l) => (
                <MenuItem key={l.id} value={l.id}>
                  {l.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        ) : null}
      </Box>
      <Box component="span" m={3}>
        {possibleRounds?.length ? (
          <FormControl className={classes.formControl}>
            <InputLabel id="select-campagin-label">Select Round</InputLabel>
            <Select
              labelId="select-campagin-label"
              value={selectedRoundID}
              onChange={(e) => setSelectedRoundID(e.target.value)}
            >
              {possibleRounds.map((l) => (
                <MenuItem key={l.id} value={l.id}>
                  {l.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        ) : null}
      </Box>
      {selectedRoundID ? (
        <>
          <Box component="div" m={3}>
            <Typography variant="h6" className={classes.title}>
              Select matches
            </Typography>
            <List>
              {selectedMatches.map((m) => (
                <div className={classes.pickedMatch}>
                  <ListItem>
                    <ListItemText
                      primary={`${m.homeTeam.name} - ${m.awayTeam.name}`}
                      secondary={new Date(m.match_start).toLocaleString()}
                    />
                    <ListItemSecondaryAction>
                      <IconButton
                        onClick={() => {
                          setDisabled(false);
                          setSelectedMatches(
                            selectedMatches.filter((sm) => sm.id !== m.id)
                          );
                        }}
                        edge="end"
                        aria-label="delete"
                      >
                        <DeleteIcon />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                </div>
              ))}
            </List>
          </Box>
          <Table size="small" style={{ position: "relative" }}>
            {loading && <div className={classes.mask}></div>}
            <TableHead>
              <TableRow>
                <TableCell>Match ID</TableCell>
                <TableCell>League</TableCell>
                <TableCell>Round Number</TableCell>
                <TableCell>
                  <TableSortLabel
                    active={orderBy === "match_start"}
                    direction={orderBy === "match_start" ? order : "asc"}
                    onClick={createSortHandler("match_start")}
                    disabled={loading}
                  >
                    Match Start
                    {orderBy === "match_start" ? (
                      <span className={classes.visuallyHidden}>
                        {order === "desc"
                          ? "sorted descending"
                          : "sorted ascending"}
                      </span>
                    ) : null}
                  </TableSortLabel>
                </TableCell>
                <TableCell>Home Team</TableCell>
                <TableCell>Away Team</TableCell>
                <TableCell>Add To Top</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {matches.map((match) => {
                const { id, match_start, round, homeTeam, awayTeam } = match;
                const matchLeague = round.League.name;

                return (
                  <TableRow key={id}>
                    <TableCell>{id}</TableCell>
                    <TableCell>{matchLeague}</TableCell>
                    <TableCell>{round?.number}</TableCell>
                    <TableCell>{match_start}</TableCell>
                    <TableCell>{homeTeam?.name}</TableCell>
                    <TableCell>{awayTeam?.name}</TableCell>
                    <TableCell>
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        disabled={
                          (!match.homeTeam?.name && !match.awayTeam?.name) ||
                          selectedMatches.some((i) => i.id === match.id)
                        }
                        onClick={() => {
                          setDisabled(false);
                          setSelectedMatches((currSelectedMatches) => [
                            ...currSelectedMatches,
                            match,
                          ]);
                        }}
                      >
                        +
                      </Button>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
            <TableFooter>
              <TablePagination
                count={count}
                page={page}
                color="primary"
                rowsPerPage={rowsPerPage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                onChangePage={(e, newPage) => {
                  setPage(newPage);
                }}
              />
            </TableFooter>
          </Table>
        </>
      ) : null}
      {loading && <LinearProgress color="secondary" />}
    </>
  );
};

export default EditCampaign;
