import React, { useState } from 'react';
import Title from './Title';
import axiosInstance from './../config/axios';
import { Button, LinearProgress, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    error: {
        color: 'red'
    },
    message: {
        color: 'green'
    }
}));

export default function SetBanner() {


    const [file, setFile] = useState(null);
    const [fileUrl, setFileUrl] = useState(null);
    const [imageLinkUrl, setImageLinkUrl] = useState('')
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState("");
    const [error, setError] = useState('');

    const classes = useStyles();

    const handleFileChange = e => {
        const imageFile = e.target.files[0];
        if (!imageFile?.name?.match(/\.(png)$/)) {
            setError('png file only');
            return false;
        }
        setError("");
        setMessage("");
        setFile(imageFile);
        setFileUrl(URL.createObjectURL(imageFile));
    }

    const onSubmit = (e) => {
        setLoading(true);
        setMessage("");
        e.preventDefault()
        const formData = new FormData()
        formData.append('banner', file);
        formData.append('imageLinkUrl', imageLinkUrl);
        axiosInstance.put("/admin/set-banner", formData, {
            headers: {
                "content-type": "multipart/form-data"
            }
        }).then(res => {
            setLoading(false);
            setMessage("file uploaded successfully");
        }).catch(err => {
            setError("error in file upload", err)
        })
    }

    return (<>
        <Title>Set Banner</Title>
        <div>
            <form onSubmit={onSubmit}>
                <div>
                    <input disabled={loading} type="file" onChange={handleFileChange} />
                </div>
                {fileUrl && <div>
                    <h3>{file.name}</h3>
                    <img src={fileUrl} alt="uploaded file" />
                </div>}
                <br />
                <div>
                    <input disabled={loading} type="text" placeholder="link url" value={imageLinkUrl} onChange={event => setImageLinkUrl(event.target.value)} />
                </div>
                <br />
                <div>
                    <Button type="submit" variant="contained" color="primary" disabled={!file || !imageLinkUrl || loading}>Upload</Button>
                </div>
                <br />
                {(message.length && <div className={classes.message}>
                    {message}
                </div>) || null}
                <br />
                {(error.length && <div className={classes.error}>
                    {error}
                </div>) || null}
            </form>
        </div>
        {loading && <LinearProgress color="secondary" />}
    </>
    )
}