import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Title from "./Title";
import {
	Box,
	Button,
	Checkbox,
	FormControl,
	FormControlLabel,
	InputLabel,
	LinearProgress,
	MenuItem,
	TableFooter,
	TablePagination,
	TableSortLabel,
	TextField,
} from "@material-ui/core";
import axiosInstance from "../config/axios";
import clsx from "clsx";
import PredictionsModal from "./predictions/predictions-modal";
import Select from "@material-ui/core/Select";

const useStyles = makeStyles((theme) => ({
	visuallyHidden: {
		border: 0,
		clip: "rect(0 0 0 0)",
		height: 1,
		margin: -1,
		overflow: "hidden",
		padding: 0,
		position: "absolute",
		top: 20,
		width: 1,
	},
	formControl: {
		margin: theme.spacing(0),
		maxWidth: 160,
		width: "100%",
	},
	mask: {
		position: "absolute",
		backgroundColor: "#FFFFFF88",
		zIndex: 9999,
		top: 0,
		bottom: 0,
		right: 0,
		left: 0,
	},
}));

const ROWS_PER_PAGE_DEFAULT = 10;

export default function Consecutive() {
	const [count, setCount] = useState(0);
	const [possibleClients, setPossibleClients] = useState([]);
	const [selectedClientID, setSelectedClientID] = useState("");
	const [possibleCampaigns, setPossibleCampaigns] = useState([]);
	const [selectedCampaignID, setSelectedCampaignID] = useState("");
	const [rowsPerPage, setRowsPerPage] = useState(ROWS_PER_PAGE_DEFAULT);
	const [page, setPage] = useState(0);
	const [rows, setRows] = useState([]);
	const [loading, setLoading] = useState(true);
	const [selectedSubmitter, setselectedSubmitter] = useState(null);
	const classes = useStyles();

	useEffect(() => {
		(async () => {
			try {
				const {
					data: { clients },
				} = await axiosInstance.get("/admin/get-all-clients");
				const currentClient = process.env.REACT_APP_ClientId
					? clients.filter((c) => +c.id === +process.env.REACT_APP_ClientId)
					: clients;
				setPossibleClients([...currentClient]);
				setLoading(false);
			} catch (error) {
				console.error(error);
				setLoading(false);
			}
		})();
	}, [setPossibleClients]);

	useEffect(() => {
		(async () => {
			try {
				const {
					data: { campaigns },
				} = await axiosInstance.get("/admin/get-all-client-campaigns", {
					params: {
						clientId: selectedClientID,
					},
				});
				setPossibleCampaigns(campaigns);
				setLoading(false);
			} catch (error) {
				console.error(error);
				setLoading(false);
			}
		})();
	}, [selectedClientID, setPossibleCampaigns]);

	const handleChangeRowsPerPage = (e) => {
		const newRowPerPage = +e?.target?.value;
		if (!newRowPerPage) return;

		const skip = page * rowsPerPage - ((page * rowsPerPage) % newRowPerPage);
		const newPage = skip / newRowPerPage;

		setRowsPerPage(newRowPerPage);
		setPage(newPage);
	};

	useEffect(() => {
		(async () => {
			setLoading(true);
			try {
				const skip = +page * +rowsPerPage;
				const { consecutiveSubmitters, count } = (
					await axiosInstance.get(`/admin/getConscutiveSubmissions`, {
						params: {
							skip: skip,
							pageSize: rowsPerPage,
							campaignId: selectedCampaignID,
						},
					})
				)?.data;
				setCount(count);
				setLoading(false);
				console.log({ consecutiveSubmitters });
				setRows(consecutiveSubmitters);
			} catch (error) {
				setLoading(false);
				console.error("api error", error);
			}
		})();
	}, [rowsPerPage, page, selectedCampaignID]);

	return (
		<React.Fragment>
			<Title>Top consecutive submitters that submitted the current round</Title>
			<Box component="div" m={3} mb={0}>
				<FormControl className={classes.formControl}>
					<InputLabel id="select-client-label">Client App</InputLabel>
					<Select
						labelId="select-campagin-label"
						value={selectedClientID}
						onChange={(e) => setSelectedClientID(e.target.value)}
					>
						{possibleClients.map((l) => (
							<MenuItem key={l.id} value={l.id}>
								{l.name}
							</MenuItem>
						))}
					</Select>
				</FormControl>
			</Box>
			<Box component="div" m={3}>
				{selectedClientID && possibleCampaigns?.length ? (
					<FormControl className={classes.formControl}>
						<InputLabel id="select-campagin-label">Select Campaign</InputLabel>
						<Select
							labelId="select-campagin-label"
							value={selectedCampaignID}
							onChange={(e) => setSelectedCampaignID(e.target.value)}
						>
							{possibleCampaigns.map((l) => (
								<MenuItem key={l.id} value={l.id}>
									{l.name}
								</MenuItem>
							))}
						</Select>
					</FormControl>
				) : null}
			</Box>
			{selectedCampaignID && (
				<Table size="small" style={{ position: "relative" }}>
					{loading && <div className={classes.mask}></div>}
					<TableHead>
						<TableRow>
							<TableCell>User ID in client</TableCell>
							<TableCell>UserId on hook-apps</TableCell>
							<TableCell>first round submitted in sequence</TableCell>
							<TableCell>last round submitted in sequence</TableCell>
							<TableCell>no. of rounds submitted consequently</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{rows.map((row) => (
							<TableRow key={row.id}>
								<TableCell>{row.User.idInClient}</TableCell>
								<TableCell>{row.UserId}</TableCell>
								<TableCell>
									{+row.firstRound.name === +row.firstRound.number
										? `Jornada ${row.firstRound.name}`
										: row.firstRound.name}
								</TableCell>
								<TableCell>{row.lastRound.name}</TableCell>
								<TableCell>{row.size}</TableCell>
							</TableRow>
						))}
					</TableBody>
					<TableFooter>
						{/* Math.floor(count / rowsPerPage) */}
						<TablePagination
							count={count}
							page={page}
							color="primary"
							rowsPerPage={rowsPerPage}
							onChangeRowsPerPage={handleChangeRowsPerPage}
							onChangePage={(e, newPage) => {
								setPage(newPage);
							}}
						/>
					</TableFooter>
				</Table>
			)}
			{loading && <LinearProgress color="secondary" />}
		</React.Fragment>
	);
}
