import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import clsx from 'clsx';
import { Box } from '@material-ui/core';

function getModalStyle() {

    return {
        top: `50%`,
        left: `50%`,
        transform: `translate(-50%, -50%)`,
    };
}

const useStyles = makeStyles((theme) => ({
    paper: {
        position: 'absolute',
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
    status: {
        fontWeight: '700',
        display: 'inline-block',
        marginLeft: 15
    },
    statusSuccess: {
        color: 'green'
    },
    statusFailure: {
        color: 'red'
    }
}));

const PredictionsModal = ({ data, handleCloseModal }) => {
	const classes = useStyles();
	// getModalStyle is not a pure function, we roll the style only on the first render
	const [modalStyle] = React.useState(getModalStyle);

	const body = (
		<div style={modalStyle} className={classes.paper}>
			<h2 id="simple-modal-title">Round {data?.round?.name}</h2>
			<p id="simple-modal-description">
				{data?.matchPredictions?.map((prediction) => (
					<div>
						<div>
							<b>Prediction: </b>
							<span>
								{prediction?.match?.homeTeam?.name} - {prediction?.home_score} :{" "}
								{prediction?.match?.awayTeam?.name} - {prediction?.away_score}
								<span
									className={clsx(
										classes.status,
										prediction?.status === "success" && classes.statusSuccess,
										prediction?.status === "failure" && classes.statusFailure
									)}
								>
									{prediction?.status}
								</span>
								{prediction?.status !== "pending" && (
									<Box component="span" ml={1}>
										({prediction?.successPoints} points)
									</Box>
								)}
							</span>
						</div>
						{prediction?.status !== "pending" && (
							<div>
								<b>Result: </b>
								<span>
									{prediction?.match?.homeTeam?.name} -{" "}
									{prediction?.match?.matchScore?.home_score} :{" "}
									{prediction?.match?.awayTeam?.name} -{" "}
									{prediction?.match?.matchScore?.away_score}
								</span>
							</div>
						)}
						<br />
					</div>
				))}
			</p>
		</div>
	);

	return (
		<div>
			<Modal
				open={!!data}
				onClose={handleCloseModal}
				aria-labelledby="simple-modal-title"
				aria-describedby="simple-modal-description"
			>
				{body}
			</Modal>
		</div>
	);
};

export default PredictionsModal;