import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Title from "./Title";
import {
	Box,
	Button,
	Checkbox,
	FormControl,
	FormControlLabel,
	InputLabel,
	LinearProgress,
	MenuItem,
	Select,
	TableFooter,
	TablePagination,
	TableSortLabel,
	TextField,
} from "@material-ui/core";
import axiosInstance from "../config/axios";
import clsx from "clsx";
import PredictionsModal from "./predictions/predictions-modal";

const useStyles = makeStyles((theme) => ({
	statusBox: {
		height: 10,
		width: 10,
		border: "1px solid black",
		margin: 5,
		display: "inline-block",
	},
	successBox: {
		backgroundColor: "green",
	},
	failureBox: {
		backgroundColor: "red",
	},
	showBtn: {
		marginLeft: 20,
	},
	formControl: {
		margin: theme.spacing(0),
		maxWidth: 160,
		width: "100%",
	},
	visuallyHidden: {
		border: 0,
		clip: "rect(0 0 0 0)",
		height: 1,
		margin: -1,
		overflow: "hidden",
		padding: 0,
		position: "absolute",
		top: 20,
		width: 1,
	},
	mask: {
		position: "absolute",
		backgroundColor: "#FFFFFF88",
		zIndex: 9999,
		top: 0,
		bottom: 0,
		right: 0,
		left: 0,
	},
}));

const ROWS_PER_PAGE_DEFAULT = 10;

export default function Predictions() {
	const [count, setCount] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(ROWS_PER_PAGE_DEFAULT);
	const [page, setPage] = useState(0);
	const [rows, setRows] = useState([]);
	const [loading, setLoading] = useState(true);
	const [possibleClients, setPossibleClients] = useState([]);
	const [selectedClientID, setSelectedClientID] = useState("");
	const [possibleCampaigns, setPossibleCampaigns] = useState([]);
	const [selectedCampaignID, setSelectedCampaignID] = useState();
	const [possibleRounds, setPossibleRounds] = useState([]);
	const [selectedSubmission, setselectedSubmission] = useState(null);
	const [emailFilter, setEmailFilter] = useState("");
	const [emailFilterTimeout, setEmailFilterTimeout] = useState(null);
	const [roundFilter, setRoundFilter] = useState("");
	const [roundFilterTimeout, setRoundFilterTimeout] = useState(null);
	const [hitsFilter, setHitsFilter] = useState("");
	const [hitsFilterTimeout, setHitsFilterTimeout] = useState(null);
	const [pointsFilter, setPointsFilter] = useState("");
	const [pointsFilterTimeout, setPointsFilterTimeout] = useState(null);
	const [orderBy, setOrderBy] = useState("success");
	const [order, setOrder] = useState("desc");
	const [lastSubOnly, setLastSubOnly] = useState(true);
	const classes = useStyles();

	const updateRows = (submissions) => {
		setRows(
			submissions.map((s) => ({
				id: s?.id ? s.id : "---",
				userId: s?.UserId ? s.UserId : "---",
				fullName:
					s?.User?.first_name && s?.User?.last_name
						? s.User.first_name + " " + s.User.last_name
						: "---",
				email: s?.User?.email ? s.User.email : "---",
				userIdInClient: s?.User?.idInClient ? s.User.idInClient : "---",
				nickname: s?.User?.nickname ? s.User.nickname : "---",
				createdAt: s?.createdAt ? s.createdAt : "---",
				isLastSubmission: s?.isLastSubmission ? s.isLastSubmission : "---",
				round: s?.CampaignRound ? s.CampaignRound : "---",
				league: s?.round?.League?.name ? s.round.League.name : "---",
				matchPredictions: s?.matchPredictions?.length
					? s.matchPredictions.sort(
							(a, b) =>
								Date.parse(a.match["match_start"]) -
								Date.parse(b.match["match_start"])
					  )
					: "---",
				successPoints: s?.successPoints ? s.successPoints : "---",
			}))
		);
	};

	/** creates a function for the specific field that called on click event and then will order by this field */
	const createSortHandler = (property) => (event) => {
		const isAsc = orderBy === property && order === "asc";
		setOrder(isAsc ? "desc" : "asc");
		setOrderBy(property);
	};

	const handleChangeRowsPerPage = (e) => {
		const newRowPerPage = +e?.target?.value;
		if (!newRowPerPage) return;

		const skip = page * rowsPerPage - ((page * rowsPerPage) % newRowPerPage);
		const newPage = skip / newRowPerPage;

		setRowsPerPage(newRowPerPage);
		setPage(newPage);
	};

	const handleSelectSubmission = (submission) => {
		setselectedSubmission(submission);
	};

	const handleCloseSubmissionModal = () => {
		setselectedSubmission(null);
	};

	const handleEmailFilterChange = (e) => {
		clearTimeout(emailFilterTimeout);
		setEmailFilterTimeout(
			setTimeout(() => {
				setLoading(true);
				setEmailFilter(e?.target?.value);
				setPage(0);
			}, 300)
		);
	};
	const handleRoundFilterChange = (e) => {
		clearTimeout(roundFilterTimeout);
		setRoundFilterTimeout(
			setTimeout(() => {
				setLoading(true);
				setRoundFilter(e?.target?.value === "No Round" ? "" : e?.target?.value);
				setPage(0);
			}, 300)
		);
	};
	const handleHitsFilterChange = (e) => {
		clearTimeout(hitsFilterTimeout);
		setHitsFilterTimeout(
			setTimeout(() => {
				setLoading(true);
				setHitsFilter(e?.target?.value);
				setPage(0);
			}, 300)
		);
	};

	const handlePointsFilterChange = (e) => {
		clearTimeout(pointsFilterTimeout);
		setPointsFilterTimeout(
			setTimeout(() => {
				setLoading(true);
				setPointsFilter(e?.target?.value);
				setPage(0);
			}, 300)
		);
	};

	useEffect(() => {
		(async () => {
			setLoading(true);
			try {
				const skip = +page * +rowsPerPage;
				const { submissions, count } = (
					await axiosInstance.get(`/admin/predictions`, {
						params: {
							skip: skip,
							pageSize: rowsPerPage,
							email_filter: emailFilter,
							round_filter: roundFilter,
							hits_filter: hitsFilter,
							points_filter: pointsFilter,
							campaign_id: selectedCampaignID,
							order: order,
							orderBy: orderBy,
							lastSubOnly: lastSubOnly,
						},
					})
				)?.data;
				setCount(count);
				setLoading(false);
				updateRows(submissions);
			} catch (error) {
				setLoading(false);
				console.error("api error", error);
			}
		})();
	}, [
		rowsPerPage,
		page,
		emailFilter,
		roundFilter,
		hitsFilter,
		pointsFilter,
		selectedCampaignID,
		order,
		orderBy,
		lastSubOnly,
	]);

	useEffect(() => {
		(async () => {
			try {
				const {
					data: { clients },
				} = await axiosInstance.get("/admin/get-all-clients");
				const currentClient = process.env.REACT_APP_ClientId
					? clients.filter((c) => +c.id === +process.env.REACT_APP_ClientId)
					: clients;
				setPossibleClients([...currentClient]);
				setLoading(false);
			} catch (error) {
				console.error(error);
				setLoading(false);
			}
		})();
	}, [setPossibleClients]);

	useEffect(() => {
		(async () => {
			try {
				const {
					data: { campaigns },
				} = await axiosInstance.get("/admin/get-all-client-campaigns", {
					params: {
						clientId: selectedClientID,
					},
				});
				setPossibleCampaigns(campaigns);
				setLoading(false);
			} catch (error) {
				console.error(error);
				setLoading(false);
			}
		})();
	}, [selectedClientID, setPossibleCampaigns]);

	useEffect(() => {
		(async () => {
			try {
				const {
					data: { rounds },
				} = await axiosInstance.get("/admin/get-all-campaign-rounds", {
					params: {
						campaignId: selectedCampaignID,
					},
				});
				setPossibleRounds([
					{ id: -1, name: "No Round" },
					...rounds.sort((a, b) => a.name - b.name),
				]);
				setLoading(false);
			} catch (error) {
				console.error(error);
				setLoading(false);
			}
		})();
	}, [selectedCampaignID, setPossibleRounds]);

	return (
		<React.Fragment>
			<Title>Recent Predictions</Title>
			<Box component="div" m={3} mb={0}>
				<FormControl className={classes.formControl}>
					<InputLabel id="select-client-label">Client App</InputLabel>
					<Select
						labelId="select-campagin-label"
						value={selectedClientID}
						onChange={(e) => setSelectedClientID(e.target.value)}
					>
						{possibleClients.map((l) => (
							<MenuItem key={l.id} value={l.id}>
								{l.name}
							</MenuItem>
						))}
					</Select>
				</FormControl>
			</Box>
			<Box component="div" m={3}>
				{selectedClientID && possibleCampaigns?.length ? (
					<FormControl className={classes.formControl}>
						<InputLabel id="select-campagin-label">Select Campaign</InputLabel>
						<Select
							labelId="select-campagin-label"
							value={selectedCampaignID}
							onChange={(e) => setSelectedCampaignID(e.target.value)}
						>
							{possibleCampaigns.map((l) => (
								<MenuItem key={l.id} value={l.id}>
									{l.name}
								</MenuItem>
							))}
						</Select>
					</FormControl>
				) : null}
			</Box>
			{selectedCampaignID && (
				<>
					<Box component="span" m={3}>
						<TextField
							id="standard-basic"
							onChange={handleEmailFilterChange}
							label="search users by Email"
						/>
					</Box>
					<Box component="span" m={3}>
						{possibleRounds?.length ? (
							<FormControl className={classes.formControl}>
								<InputLabel id="select-campagin-label">Select Round</InputLabel>
								<Select
									labelId="select-campagin-label"
									value={roundFilter}
									onChange={handleRoundFilterChange}
								>
									{possibleRounds.map((l) => (
										<MenuItem key={l.id} value={l.name}>
											{l.name}
										</MenuItem>
									))}
								</Select>
							</FormControl>
						) : null}
					</Box>
					<Box component="span" m={3}>
						<TextField
							id="standard-basic"
							onChange={handleHitsFilterChange}
							label="search by Hits"
						/>
					</Box>
					<Box component="span" m={3}>
						<TextField
							id="standard-basic"
							onChange={handlePointsFilterChange}
							label="search by Points"
						/>
					</Box>
					<Box component="div" m={3}>
						<FormControlLabel
							control={
								<Checkbox
									checked={lastSubOnly}
									onChange={() => {
										setLastSubOnly(!lastSubOnly);
									}}
									name="checkedB"
									color="primary"
								/>
							}
							label="Last submission only"
						/>
					</Box>

					<Table size="small" style={{ position: "relative" }}>
						{loading && <div className={classes.mask}></div>}
						<TableHead>
							<TableRow>
								<TableCell>User ID</TableCell>
								<TableCell>League</TableCell>
								<TableCell>User name</TableCell>
								<TableCell>User Email</TableCell>
								<TableCell>userIdInClient</TableCell>
								<TableCell>nickname</TableCell>
								<TableCell>
									<TableSortLabel
										active={orderBy === "date"}
										direction={orderBy === "date" ? order : "desc"}
										onClick={createSortHandler("date")}
										disabled={loading}
									>
										Date submitted
										{orderBy === "date" ? (
											<span className={classes.visuallyHidden}>
												{order === "desc"
													? "sorted descending"
													: "sorted ascending"}
											</span>
										) : null}
									</TableSortLabel>
								</TableCell>
								<TableCell>Is last submission</TableCell>
								<TableCell>Predictions</TableCell>
								<TableCell>
									<TableSortLabel
										active={orderBy === "success"}
										direction={orderBy === "success" ? order : "desc"}
										onClick={createSortHandler("success")}
										disabled={loading}
									>
										Success Points
										{orderBy === "success" ? (
											<span className={classes.visuallyHidden}>
												{order === "desc"
													? "sorted descending"
													: "sorted ascending"}
											</span>
										) : null}
									</TableSortLabel>
								</TableCell>
								<TableCell>Round number</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{rows.map((row) => (
								<TableRow key={row.id}>
									<TableCell>{row.userId}</TableCell>
									<TableCell>{row.league}</TableCell>
									<TableCell>{row.fullName}</TableCell>
									<TableCell>{row.email}</TableCell>
									<TableCell>{row.userIdInClient}</TableCell>
									<TableCell>{row.nickname}</TableCell>
									<TableCell>{row.createdAt}</TableCell>
									<TableCell
										style={{ color: row.isLastSubmission ? "green" : "red" }}
									>
										{row.isLastSubmission ? "yes" : "no"}
									</TableCell>
									<TableCell>
										{row.matchPredictions.map((p) => (
											<div
												key={p.id}
												className={clsx(
													classes.statusBox,
													p.status === "success" && classes.successBox,
													p.status === "failure" && classes.failureBox
												)}
											></div>
										))}
										<Button
											color="primary"
											className={classes.showBtn}
											onClick={handleSelectSubmission.bind(null, row)}
										>
											Show
										</Button>
									</TableCell>
									<TableCell>{row.successPoints}</TableCell>
									<TableCell>{row.round?.name}</TableCell>
								</TableRow>
							))}
						</TableBody>
						<TableFooter>
							{/* Math.floor(count / rowsPerPage) */}
							<TablePagination
								count={count}
								page={page}
								color="primary"
								rowsPerPage={rowsPerPage}
								onChangeRowsPerPage={handleChangeRowsPerPage}
								onChangePage={(e, newPage) => {
									setPage(newPage);
								}}
							/>
						</TableFooter>
					</Table>
				</>
			)}
			{loading && <LinearProgress color="secondary" />}
			<PredictionsModal
				data={selectedSubmission}
				handleCloseModal={handleCloseSubmissionModal}
			/>
		</React.Fragment>
	);
}
