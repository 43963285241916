import { createContext } from "react";

const StateContext = createContext();

const stateReducer = (state, action) => {
    switch (action.type) {
        case "SET_USER":
            return { ...state, user: action?.payload }
        case "CLEAR_USER":
            return { ...state, user: null }
        default:
            throw new Error(`action ${action.type} not found`)
    }
}

export { StateContext, stateReducer };