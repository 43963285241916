import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
	Box,
	Modal,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
} from "@material-ui/core";
import moment from "moment";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";

const getModalStyle = () => {
	return {
		top: `50%`,
		left: `50%`,
		transform: `translate(-50%, -50%)`,
	};
};

const useStyles = makeStyles((theme) => ({
	paper: {
		position: "absolute",
		backgroundColor: theme.palette.background.paper,
		border: "2px solid #000",
		boxShadow: theme.shadows[5],
		padding: theme.spacing(2, 4, 3),
		maxHeight: "70vh",
		minWidth: "50rem",
		overflowY: "auto",
	},
	checkedIcon: {
		fontSize: "1rem",
		marginLeft: "1rem",
	},
}));

const GroupRow = (props) => {
	const { row } = props;

	return (
		<React.Fragment>
			<Box padding={2}>
				<h4>{row.TournamentGroup.displayName}</h4>
				<Box m={1}>
					<Table size="small" aria-label="purchases">
						<TableHead>
							<TableRow>
								<TableCell>rank</TableCell>
								<TableCell>Team</TableCell>
								<TableCell>status</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{row.GroupPredictionRankings.map((prediction) => (
								<TableRow key={prediction.id}>
									<TableCell>{prediction.ranking}</TableCell>
									<TableCell component="th" scope="row">
										<Box component="span" mr={1}>
											<img
												alt="flag"
												style={{ width: "1rem" }}
												src={prediction.team?.flag_img}
											/>
										</Box>
										<Box component="span">{prediction.team?.name}</Box>
									</TableCell>
									<TableCell>{prediction.status}</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				</Box>
			</Box>
		</React.Fragment>
	);
};

const RoundRow = (props) => {
	const classes = useStyles();
	const { row } = props;
	console.log({ row });
	return (
		<React.Fragment>
			<Box padding={2}>
				<h4>{row.name}</h4>
				<Box m={1}>
					<TableContainer component={Paper}>
						<Table aria-label="collapsible table">
							<TableHead>
								<TableRow>
									<TableCell>Match start</TableCell>
									<TableCell>Home Team</TableCell>
									<TableCell>Away Team</TableCell>
									<TableCell align="right">Status</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{row.matches.map((mwt) => (
									<TableRow>
										<TableCell>
											<Box mr={1} noWrap={true} component="span">
												{mwt?.matchStart?.format("lll")}
											</Box>
										</TableCell>
										<TableCell>
											<Box component="span" mr={1}>
												<img
													alt="home flag"
													style={{ width: "1rem" }}
													src={mwt?.homeTeam?.flag_img}
												/>
											</Box>
											<Box component="span">
												{mwt?.homeTeam?.name}
												{mwt.result === "home_win" && (
													<CheckCircleIcon className={classes.checkedIcon} />
												)}
											</Box>
										</TableCell>
										<TableCell>
											<Box component="span" mr={1}>
												<img
													alt="away flag"
													style={{ width: "1rem" }}
													src={mwt?.awayTeam?.flag_img}
												/>
											</Box>
											<Box component="span">
												{mwt?.awayTeam?.name}
												{mwt.result === "away_win" && (
													<CheckCircleIcon className={classes.checkedIcon} />
												)}
											</Box>
										</TableCell>
										<TableCell align="right">{mwt?.status}</TableCell>
									</TableRow>
								))}
							</TableBody>
						</Table>
					</TableContainer>
				</Box>
			</Box>
		</React.Fragment>
	);
};

export const TournamentPredictionsModal = ({
	submission,
	handleCloseModal,
	tournament,
	onlyGroup,
}) => {
	const classes = useStyles();
	// getModalStyle is not a pure function, we roll the style only on the first render
	const [modalStyle] = React.useState(getModalStyle);
	console.log({ submission, handleCloseModal, tournament, onlyGroup });
	if (!submission || !tournament) {
		return <div></div>;
	}

	const matches = tournament?.rounds?.map((r) => r.matches)?.flat();

	const matchesPredictions = submission?.tournamentMatchPredictions && [
		...submission?.tournamentMatchPredictions?.sort(
			(mp1, mp2) =>
				+new Date(mp1.match.match_start) - +new Date(mp2.match.match_start)
		),
	];
	if (!matchesPredictions) {
		return <></>;
	}
	let roundsWithMatches,
		matchesWithTeams = [];

	for (const mp of matchesPredictions) {
		const match = matches.find((m) => m.id === mp.matchId);
		let awayTeamFromGroup,
			awayTeamFromRank,
			homeTeamFromGroup,
			homeTeamFromRank;
		if (match.awayTeamFrom.previousStage === "ranking") {
			if (mp.awayTeamFromGroup) {
				awayTeamFromGroup = mp.awayTeamFromGroup;
			} else {
				awayTeamFromGroup = match.awayTeamFrom.previousPosition[0].group;
			}
			awayTeamFromRank = +match.awayTeamFrom.previousPosition[0].ranking;

			if (mp.homeTeamFromGroup) {
				homeTeamFromGroup = mp.homeTeamFromGroup;
			} else {
				homeTeamFromGroup = match.homeTeamFrom.previousPosition[0].group;
			}

			homeTeamFromRank = +match.homeTeamFrom.previousPosition[0].ranking;
			console.log({ mp });
			matchesWithTeams.push({
				matchStart: moment(mp.match.match_start).local(),
				matchId: mp.match.idInApi,
				result: mp.result,
				roundId: mp.match.roundId,
				status: mp.status,
				awayTeam: submission.tournamentMatchPredictions.find(
					(tmp) => +tmp.match.id === +mp.match.id
				)?.match?.awayTeam,
				homeTeam: submission.tournamentMatchPredictions.find(
					(tmp) => +tmp.matchId === +mp.matchId
				)?.match?.homeTeam,
				// awayTeam: submission.groupPredictions
				// 	.find((g) => g.TournamentGroup.key === awayTeamFromGroup)
				// 	.GroupPredictionRankings.find(
				// 		(gpr) => gpr.ranking === awayTeamFromRank
				// 	).team,
				// homeTeam: submission.groupPredictions
				// 	.find((g) => g.TournamentGroup.key === homeTeamFromGroup)
				// 	.GroupPredictionRankings.find(
				// 		(gpr) => gpr.ranking === homeTeamFromRank
				// 	).team,
			});
		} else if (match.awayTeamFrom.previousStage === "playoff") {
			let awayTeamFromMatch, homeTeamFromMatch;
			awayTeamFromMatch = matchesWithTeams.find(
				(mwt) => mwt.matchId === +match.awayTeamFrom.previousPosition.matchApiId
			);
			homeTeamFromMatch = matchesWithTeams.find(
				(mwt) => mwt.matchId === +match.homeTeamFrom.previousPosition.matchApiId
			);
			let awayTeam =
				awayTeamFromMatch.result === "away_win"
					? awayTeamFromMatch.awayTeam
					: awayTeamFromMatch.homeTeam;
			let homeTeam =
				homeTeamFromMatch.result === "away_win"
					? homeTeamFromMatch.awayTeam
					: homeTeamFromMatch.homeTeam;
			matchesWithTeams.push({
				matchStart: moment(mp.match.match_start).local(),
				roundId: mp.match.roundId,
				matchId: mp.match.idInApi,
				result: mp.result,
				status: mp.status,
				awayTeam,
				homeTeam,
			});
		}
	}
	roundsWithMatches = tournament.rounds
		.sort((r1, r2) => r1.number - r2.number)
		.map((r) => ({
			name: +r.name === 1 ? "Last 16" : r.name, // the sportDataApi has bug that shows round of sixteen as round 1
			matches: matchesWithTeams.filter((mwt) => mwt.roundId === r.id),
		}));

	const body = submission ? (
		<div style={modalStyle} className={classes.paper}>
			<h2 id="simple-modal-title">User ID: {submission?.User?.idInClient}</h2>
			{submission?.groupPredictions?.length ? (
				<>
					<h3>Groups Predictions</h3>
					{submission?.groupPredictions?.map((row) => (
						<GroupRow key={row.name} row={row} />
					))}
				</>
			) : null}
			{!onlyGroup && (
				<>
					<h3>Matches Predictions</h3>
					{roundsWithMatches.map((row) => (
						<RoundRow key={row.name} row={row} />
					))}
				</>
			)}
			{/* <h3>Other Predictions</h3>
			<Box paddingY={".5rem"}>
				<b>predictedCards: </b>
				{submission?.predictedCards || "?"}
			</Box>
			<Box paddingY={".5rem"}>
				<b>predictedCorners: </b>
				{submission?.predictedCorners || "?"}
			</Box>
			<Box paddingY={".5rem"}>
				<b>predictedGoals: </b>
				{submission?.predictedGoals || "?"}
			</Box> */}
		</div>
	) : null;

	return (
		<div>
			<Modal
				open={!!submission}
				onClose={handleCloseModal}
				aria-labelledby="simple-modal-title"
				aria-describedby="simple-modal-description"
			>
				{body}
			</Modal>
		</div>
	);
};

export default TournamentPredictionsModal;
