import {
	Box,
	FormControl,
	InputLabel,
	LinearProgress,
	makeStyles,
	MenuItem,
	Select,
	Table,
	TableBody,
	TableCell,
	TableFooter,
	TableHead,
	TablePagination,
	TableRow,
	TableSortLabel,
	TextField,
} from "@material-ui/core";
import { useEffect, useState } from "react";
import axiosInstance from "../config/axios";
import { PeriodTypeEnum } from "../enums/period-type-enum";
import { RoundStageEnum } from "../enums/round-stage-enum";
import Title from "./Title";

const useStyles = makeStyles((theme) => ({
	statusBox: {
		height: 10,
		width: 10,
		border: "1px solid black",
		margin: 5,
		display: "inline-block",
	},
	successBox: {
		backgroundColor: "green",
	},
	failureBox: {
		backgroundColor: "red",
	},
	showBtn: {
		marginLeft: 20,
	},
	formControl: {
		margin: theme.spacing(0),
		maxWidth: 160,
		width: "100%",
	},
	visuallyHidden: {
		border: 0,
		clip: "rect(0 0 0 0)",
		height: 1,
		margin: -1,
		overflow: "hidden",
		padding: 0,
		position: "absolute",
		top: 20,
		width: 1,
	},
	mask: {
		position: "absolute",
		backgroundColor: "#FFFFFF88",
		zIndex: 9999,
		top: 0,
		bottom: 0,
		right: 0,
		left: 0,
	},
}));

const ROWS_PER_PAGE_DEFAULT = 10;

const Leaderboard = () => {
	const [count, setCount] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(ROWS_PER_PAGE_DEFAULT);
	const [page, setPage] = useState(0);
	const [rows, setRows] = useState([]);
	const [loading, setLoading] = useState(true);
	const [possibleClients, setPossibleClients] = useState([]);
	const [selectedClientID, setSelectedClientID] = useState("");
	const [possibleCampaigns, setPossibleCampaigns] = useState([]);
	const [selectedCampaignID, setSelectedCampaignID] = useState();
	const [userFilter, setUserFilter] = useState("");
	const [userFilterTimeout, setUserFilterTimeout] = useState(null);
	const [pointsFilter, setPointsFilter] = useState("");
	const [pointsFilterTimeout, setPointsFilterTimeout] = useState(null);
	const [roundStageFilter, setRoundStageFilter] = useState(
		PeriodTypeEnum.SEASON
	);
	const [orderBy, setOrderBy] = useState("success");
	const [order, setOrder] = useState("desc");
	const classes = useStyles();

	const updateRows = (users) => {
		setRows(
			users?.map((s) => ({
				id: s?.id,
				userId: s?.userId,
				userIdInClient: s?.idInClient,
				nickname: s?.nickname,
				rank: s?.rank,
				successPoints: s?.points,
			}))
		);
	};

	/** creates a function for the specific field that called on click event and then will order by this field */
	const createSortHandler = (property) => (event) => {
		const isAsc = orderBy === property && order === "asc";
		setOrder(isAsc ? "desc" : "asc");
		setOrderBy(property);
	};

	const handleChangeRowsPerPage = (e) => {
		const newRowPerPage = +e?.target?.value;
		if (!newRowPerPage) return;

		const skip = page * rowsPerPage - ((page * rowsPerPage) % newRowPerPage);
		const newPage = skip / newRowPerPage;

		setRowsPerPage(newRowPerPage);
		setPage(newPage);
	};

	const handleUserFilterChange = (e) => {
		clearTimeout(userFilterTimeout);
		setUserFilterTimeout(
			setTimeout(() => {
				setLoading(true);
				setUserFilter(e?.target?.value);
				setPage(0);
			}, 300)
		);
	};

	const handlePointsFilterChange = (e) => {
		console.log({ e });
		clearTimeout(pointsFilterTimeout);
		setPointsFilterTimeout(
			setTimeout(() => {
				setLoading(true);
				setPointsFilter(e?.target?.value);
				setPage(0);
			}, 300)
		);
	};

	useEffect(() => {
		(async () => {
			setLoading(true);
			try {
				const skip = +page * +rowsPerPage;
				const { data } = await axiosInstance.get(`/admin/leaderboard`, {
					params: {
						campaignId: selectedCampaignID,
						period: roundStageFilter,
						type: roundStageFilter,
						skip: skip,
						pageSize: rowsPerPage,
						points_filter: +pointsFilter,
						user_filter: userFilter,
						stage_filter: roundStageFilter,
					},
				});
				const { leaderboard, count } = data;
				// const { submissions, count } = (
				// 	await axiosInstance.get(`/admin/predictions`, {
				// 		params: {
				// 			skip: skip,
				// 			pageSize: rowsPerPage,
				// 			email_filter: userFilter,

				// 			points_filter: pointsFilter,
				// 			campaign_id: selectedCampaignID,
				// 			order: order,
				// 			orderBy: orderBy,
				// 		},
				// 	})
				// )?.data;
				console.log({ leaderboard, count });
				if (leaderboard) {
					setCount(count);
					setLoading(false);
					updateRows(leaderboard);
				}
			} catch (error) {
				setLoading(false);
				console.error("api error", error);
			}
		})();
	}, [
		rowsPerPage,
		page,
		pointsFilter,
		selectedCampaignID,
		order,
		orderBy,
		count,
		userFilter,
		roundStageFilter,
	]);

	useEffect(() => {
		(async () => {
			try {
				const {
					data: { clients },
				} = await axiosInstance.get("/admin/get-all-clients");
				const currentClient = process.env.REACT_APP_ClientId
					? clients.filter((c) => +c.id === +process.env.REACT_APP_ClientId)
					: clients;
				setPossibleClients([...currentClient]);
				setLoading(false);
			} catch (error) {
				console.error(error);
				setLoading(false);
			}
		})();
	}, [setPossibleClients]);

	useEffect(() => {
		(async () => {
			try {
				const {
					data: { campaigns },
				} = await axiosInstance.get("/admin/get-all-client-campaigns", {
					params: {
						clientId: selectedClientID,
					},
				});
				setPossibleCampaigns(campaigns);
				setLoading(false);
			} catch (error) {
				console.error(error);
				setLoading(false);
			}
		})();
	}, [selectedClientID, setPossibleCampaigns]);

	useEffect(() => {
		console.log({ possibleClients });
	}, [possibleClients]);

	return (
		<>
			<Title>Pick-4 Leaderboard Predictions</Title>
			<Box component="div" m={3} mb={0}>
				<FormControl className={classes.formControl}>
					<InputLabel id="select-client-label">Client App</InputLabel>
					<Select
						labelId="select-campagin-label"
						value={selectedClientID}
						onChange={(e) => setSelectedClientID(e.target.value)}
					>
						{possibleClients.map((l) => (
							<MenuItem key={l.id} value={l.id}>
								{l.name}
							</MenuItem>
						))}
					</Select>
				</FormControl>
			</Box>
			<Box component="div" m={3}>
				{selectedClientID && possibleCampaigns?.length ? (
					<FormControl className={classes.formControl}>
						<InputLabel id="select-campagin-label">Select Campaign</InputLabel>
						<Select
							labelId="select-campagin-label"
							value={selectedCampaignID}
							onChange={(e) => setSelectedCampaignID(e.target.value)}
						>
							{possibleCampaigns.map((l) => (
								<MenuItem key={l.id} value={l.id}>
									{l.name}
								</MenuItem>
							))}
						</Select>
					</FormControl>
				) : null}
			</Box>
			{selectedCampaignID && (
				<>
					<Box component="div" m={3} mb={0}>
						<FormControl className={classes.formControl}>
							<InputLabel id="select-round-stage-label">
								Select Round Stage
							</InputLabel>
							<Select
								labelId="select-round-stage-label"
								value={roundStageFilter}
								onChange={(e) => setRoundStageFilter(e.target.value)}
							>
								{[
									{ value: PeriodTypeEnum.SEASON, name: "All Tournament" },
									{ value: PeriodTypeEnum.GROUP, name: "Group Stage" },
								].map((l) => (
									<MenuItem key={l.value} value={l.value}>
										{l.name}
									</MenuItem>
								))}
							</Select>
						</FormControl>
					</Box>
					<Box component="span" m={3} mt={0} mb={0}>
						<TextField
							id="standard-basic"
							onChange={handleUserFilterChange}
							label="search users"
						/>
					</Box>
					<Box component="span" m={3} mt={0}>
						<TextField
							id="standard-basic"
							onChange={handlePointsFilterChange}
							label="search by Points"
						/>
					</Box>

					<Table size="small" style={{ position: "relative" }}>
						{loading && <div className={classes.mask}></div>}
						<TableHead>
							<TableRow>
								<TableCell>ID</TableCell>
								<TableCell>User Id In Client</TableCell>
								<TableCell>Nickname</TableCell>
								<TableCell>Rank</TableCell>
								<TableCell>
									<TableSortLabel
										active={orderBy === "success"}
										direction={orderBy === "success" ? order : "desc"}
										onClick={createSortHandler("success")}
										disabled={loading}
									>
										Points
										{orderBy === "success" ? (
											<span className={classes.visuallyHidden}>
												{order === "desc"
													? "sorted descending"
													: "sorted ascending"}
											</span>
										) : null}
									</TableSortLabel>
								</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{rows.map((row) => (
								<TableRow key={row.id}>
									<TableCell>{row.userId}</TableCell>
									<TableCell>{row.userIdInClient}</TableCell>
									<TableCell>{row.nickname}</TableCell>
									<TableCell style={{ textAlign: "center" }}>
										{row.rank}
									</TableCell>
									<TableCell style={{ textAlign: "center" }}>
										{row.successPoints}
									</TableCell>
								</TableRow>
							))}
						</TableBody>
						<TableFooter>
							{/* Math.floor(count / rowsPerPage) */}
							<TablePagination
								count={count}
								page={page}
								color="primary"
								rowsPerPage={rowsPerPage}
								onChangeRowsPerPage={handleChangeRowsPerPage}
								onChangePage={(e, newPage) => {
									setPage(newPage);
								}}
							/>
						</TableFooter>
					</Table>
				</>
			)}
			{loading && <LinearProgress color="secondary" />}
		</>
	);
};

export default Leaderboard;
