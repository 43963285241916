import React, { useEffect, useState } from "react";
import moment from "moment";
import {
	Box,
	Typography,
	makeStyles,
	Table,
	TableHead,
	TableRow,
	TableCell,
	TableSortLabel,
	TableBody,
	Button,
	TableFooter,
	TablePagination,
	LinearProgress,
	TextField,
	FormControlLabel,
	Checkbox,
	FormControl,
	InputLabel,
	Select,
	MenuItem,
} from "@material-ui/core";
import axiosInstance from "../../config/axios";
import { PeriodTypeEnum } from "../../enums/period-type-enum";
import TournamentPredictionsModal from "./TournamentPredictionsModal";
import Title from "../Title";

const pickers = [
	{
		displayName: "Group Stage",
		name: PeriodTypeEnum.GROUP,
	},
	{
		displayName: "All Tournament",
		name: PeriodTypeEnum.TOURNAMENT,
	},
	{
		displayName: "Late/Knockout",
		name: PeriodTypeEnum.KNOCKOUT,
	},
];

// /admin/getTournamentSubmissionsCount?tournamentId=271
const useStyles = makeStyles((theme) => ({
	statusBox: {
		height: 10,
		width: 10,
		border: "1px solid black",
		margin: 5,
		display: "inline-block",
	},
	successBox: {
		backgroundColor: "green",
	},
	failureBox: {
		backgroundColor: "red",
	},
	showBtn: {
		marginLeft: 20,
	},
	visuallyHidden: {
		border: 0,
		clip: "rect(0 0 0 0)",
		height: 1,
		margin: -1,
		overflow: "hidden",
		padding: 0,
		position: "absolute",
		top: 20,
		width: 1,
	},
	mask: {
		position: "absolute",
		backgroundColor: "#FFFFFF88",
		zIndex: 9999,
		top: 0,
		bottom: 0,
		right: 0,
		left: 0,
	},
	formControl: {
		margin: theme.spacing(0),
		maxWidth: 200,
		width: "100%",
	},
}));

const ROWS_PER_PAGE_DEFAULT = 10;

const Submissions = () => {
	const [possibleClients, setPossibleClients] = useState([]);
	const [selectedClientID, setSelectedClientID] = useState("");
	const [rowsPerPage, setRowsPerPage] = useState(ROWS_PER_PAGE_DEFAULT);
	const [page, setPage] = useState(0);
	const [rows, setRows] = useState([]);
	const [userIdFilter, setUserIdFilter] = useState("");
	const [userIdFilterTimeout, setUserIdFilterTimeout] = useState(null);
	const [tournamentFilter, setTournamentFilter] = useState(
		PeriodTypeEnum.GROUP
	);
	const [tournamentFilterTimeout, setTournamentFilterTimeout] = useState(null);
	const [orderBy, setOrderBy] = useState("points");
	const [order, setOrder] = useState("desc");
	const [outOfTotalPoints, setOutOfTotalPoints] = useState(null);
	const [lastSubOnly, setLastSubOnly] = useState(true);
	const [loading, setLoading] = useState(true);
	const [count, setCount] = useState(null);
	const [selectedSubmission, setselectedSubmission] = useState(null);
	const [tournament, setTournament] = useState(null);
	const classes = useStyles();

	const handleSelectSubmission = (submission) => {
		setselectedSubmission(submission);
	};

	const handleCloseSubmissionModal = () => {
		setselectedSubmission(null);
	};

	const updateRows = (submissions) => {
		setRows(submissions);
	};

	/** creates a function for the specific field that called on click event and then will order by this field */
	const createSortHandler = (property) => (event) => {
		const isAsc = orderBy === property && order === "asc";
		setOrder(isAsc ? "desc" : "asc");
		setOrderBy(property);
	};

	const handleChangeRowsPerPage = (e) => {
		const newRowPerPage = +e?.target?.value;
		if (!newRowPerPage) return;

		const skip = page * rowsPerPage - ((page * rowsPerPage) % newRowPerPage);
		const newPage = skip / newRowPerPage;

		setRowsPerPage(newRowPerPage);
		setPage(newPage);
	};

	const handleUserIdFilterChange = (e) => {
		clearTimeout(userIdFilterTimeout);
		setUserIdFilterTimeout(
			setTimeout(() => {
				setLoading(true);
				setUserIdFilter(e?.target?.value);
				setPage(0);
			}, 300)
		);
	};

	const handleTournamentFilterChange = (e) => {
		clearTimeout(tournamentFilterTimeout);
		setTournamentFilterTimeout(
			setTimeout(() => {
				setLoading(true);
				setTournamentFilter(e?.target?.value);
				setPage(0);
			}, 300)
		);
	};

	useEffect(() => {
		(async () => {
			try {
				const {
					data: { clients },
				} = await axiosInstance.get("/admin/get-all-clients");
				const currentClient = process.env.REACT_APP_ClientId
					? clients.filter((c) => +c.id === +process.env.REACT_APP_ClientId)
					: clients;
				setPossibleClients([...currentClient]);
				setLoading(false);
			} catch (error) {
				console.error(error);
				setLoading(false);
			}
		})();
	}, [setPossibleClients]);

	useEffect(() => {
		let pts = 96;
		switch (tournamentFilter) {
			case PeriodTypeEnum.KNOCKOUT:
				pts = 45;
				break;
			case PeriodTypeEnum.TOURNAMENT:
				pts = 144;
				break;
			default:
				pts = 96;
				break;
		}
		setOutOfTotalPoints(pts);
	}, [tournamentFilter]);

	useEffect(() => {
		(async () => {
			setLoading(true);
			try {
				if (!selectedClientID) return;
				const skip = +page * +rowsPerPage;
				const [tournament, submissionsRes] = await Promise.all([
					axiosInstance.get(
						`/api/get-tournament?tournamentId=77&clientId=${selectedClientID}`
					),
					axiosInstance.get(`/admin/tournamentPredictions`, {
						params: {
							tournamentId: 77,
							clientId: selectedClientID,
							skip: skip,
							pageSize: rowsPerPage,
							userid_filter: userIdFilter,
							tournament_filter: tournamentFilter,
							order: order,
							orderBy: orderBy,
							lastSubOnly: lastSubOnly,
						},
					}),
				]);
				const { submissions, count } = submissionsRes?.data;
				setCount(count);
				setLoading(false);
				updateRows(submissions);
				tournament?.data?.tournament &&
					setTournament(tournament.data.tournament);
			} catch (error) {
				setLoading(false);
				console.error("api error", error);
			}
		})();
	}, [
		rowsPerPage,
		page,
		userIdFilter,
		order,
		orderBy,
		lastSubOnly,
		tournamentFilter,
		selectedClientID,
	]);

	return (
		<React.Fragment>
			<Title>Tournament Predictions</Title>
			<Box component="div" m={3} mb={0}>
				<FormControl className={classes.formControl}>
					<InputLabel id="select-client-label">Client App</InputLabel>
					<Select
						labelId="select-campagin-label"
						value={selectedClientID}
						onChange={(e) => setSelectedClientID(e.target.value)}
					>
						{possibleClients.map((l) => (
							<MenuItem key={l.id} value={l.id}>
								{l.name}
							</MenuItem>
						))}
					</Select>
				</FormControl>
			</Box>
			{selectedClientID && (
				<>
					<Box component="span" m={3} style={{ whiteSpace: "nowrap" }}>
						<TextField
							id="standard-basic"
							onChange={handleUserIdFilterChange}
							label="search users by UserId"
						/>
					</Box>
					<Box component="span" m={3} style={{ whiteSpace: "nowrap" }}>
						<FormControl className={classes.formControl}>
							<InputLabel id="select-campagin-label">
								Select Tournament Filter
							</InputLabel>
							<Select
								labelId="select-campagin-label"
								value={tournamentFilter}
								onChange={handleTournamentFilterChange}
							>
								{pickers.map((l) => (
									<MenuItem key={l.name} value={l.name}>
										{l.displayName}
									</MenuItem>
								))}
							</Select>
						</FormControl>
					</Box>
					{count !== null && count !== undefined && (
						<Box component="span" m={3}>
							<p>
								number of{" "}
								{lastSubOnly
									? "unique submission (doesn't include edits)"
									: "total subimssions(include edits)"}
								: {count}
							</p>
						</Box>
					)}
					<Box component="div" m={3}>
						<FormControlLabel
							control={
								<Checkbox
									checked={lastSubOnly}
									onChange={() => {
										setLastSubOnly(!lastSubOnly);
									}}
									name="checkedB"
									color="primary"
								/>
							}
							label="Last submission only"
						/>
					</Box>
					<Table size="small" style={{ position: "relative" }}>
						{loading && <div className={classes.mask}></div>}
						<TableHead>
							<TableRow>
								<TableCell>id</TableCell>
								{/* <TableCell>Email</TableCell> */}
								<TableCell>User ID</TableCell>
								<TableCell>Nickname</TableCell>
								<TableCell>
									<TableSortLabel
										active={orderBy === "date"}
										direction={orderBy === "date" ? order : "desc"}
										onClick={createSortHandler("date")}
										disabled={loading}
									>
										Date submitted
										{orderBy === "date" ? (
											<span className={classes.visuallyHidden}>
												{order === "desc"
													? "sorted descending"
													: "sorted ascending"}
											</span>
										) : null}
									</TableSortLabel>
								</TableCell>
								<TableCell>Is last submission</TableCell>
								<TableCell>
									<TableSortLabel
										active={orderBy === "success"}
										direction={orderBy === "success" ? order : "desc"}
										onClick={createSortHandler("success")}
										disabled={loading}
									>
										Success Rate
										{orderBy === "success" ? (
											<span className={classes.visuallyHidden}>
												{order === "desc"
													? "sorted descending"
													: "sorted ascending"}
											</span>
										) : null}
									</TableSortLabel>
								</TableCell>
								<TableCell>
									<TableSortLabel
										active={orderBy === "points"}
										direction={orderBy === "points" ? order : "desc"}
										onClick={createSortHandler("points")}
										disabled={loading}
									>
										Success Points
										{orderBy === "points" ? (
											<span className={classes.visuallyHidden}>
												{order === "desc"
													? "sorted descending"
													: "sorted ascending"}
											</span>
										) : null}
									</TableSortLabel>
								</TableCell>
								<TableCell>Predictions</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{rows.map((submission) => {
								const row = {
									id: submission?.User?.id,
									email: submission?.User?.email,
									userId: submission?.User?.idInClient,
									nickname: submission?.User?.nickname,
									success: submission?.successRate,
									points:
										tournamentFilter === PeriodTypeEnum.GROUP
											? submission?.groupStagePoints
											: submission?.successPoints,
									createdAt: moment(submission?.createdAt).local(),
									isLastSubmission: submission?.isLastSubmission,
									submissionId: submission?.id,
								};
								return (
									<TableRow key={row.submissionId}>
										<TableCell>{row.id}</TableCell>
										{/* <TableCell>{row.email}</TableCell> */}
										<TableCell>{row.userId}</TableCell>
										<TableCell>{row.nickname}</TableCell>
										<TableCell>{row.createdAt.format("lll")}</TableCell>
										<TableCell
											style={{ color: row.isLastSubmission ? "green" : "red" }}
										>
											{row.isLastSubmission ? "yes" : "no"}
										</TableCell>
										<TableCell>{row.success}</TableCell>
										<TableCell>
											{row.points} / {outOfTotalPoints}
										</TableCell>
										<TableCell>
											<Button
												color="primary"
												className={classes.showBtn}
												onClick={handleSelectSubmission.bind(null, submission)}
											>
												Show
											</Button>
										</TableCell>
									</TableRow>
								);
							})}
						</TableBody>
						<TableFooter>
							{/* Math.floor(count / rowsPerPage) */}
							<TablePagination
								count={count}
								page={page}
								color="primary"
								rowsPerPage={rowsPerPage}
								onChangeRowsPerPage={handleChangeRowsPerPage}
								onChangePage={(e, newPage) => {
									setPage(newPage);
								}}
							/>
						</TableFooter>
					</Table>
					{loading && <LinearProgress color="secondary" />}
					<TournamentPredictionsModal
						{...{ tournament }}
						submission={selectedSubmission}
						onlyGroup={tournamentFilter === PeriodTypeEnum.GROUP}
						handleCloseModal={handleCloseSubmissionModal}
					/>
				</>
			)}
		</React.Fragment>
	);
};

export default Submissions;
